import React from "react"
import MapMarker from "../../images/marker-icons/mapbox-marker-icon-red.svg"
import WaypointMarkerImg from "../../images/marker-icons/thru_waypoint.png"
import { Marker } from "react-map-gl"

const DriveMarker = ({ lat, lng, type, onClick, onDragEnd, text = null }) => {
  return (
    <Marker
      latitude={lat}
      longitude={lng}
      offsetTop={type === "bookend" ? -25 : -15}
      offsetLeft={type === "bookend" ? -10 : -7}
      onClick={onClick}
      draggable={true}
      onDragEnd={(event) => onDragEnd(event, { lat, lng })}
    >
      <img
        src={type === "bookend" ? MapMarker : WaypointMarkerImg}
        draggable="false"
        style={{
          userSelect: "none",
          MozUserSelect: "none",
          WebkitUserSelect: "none",
          msUserSelect: "none",
        }}
        alt="waypoint"
      />
      {text ? (
        <div
          style={{
            position: "absolute",
            top: 4,
            color: "black",
            left: 6,
            fontSize: 12,
            fontWeight: "bold",
          }}
        >
          {text}
        </div>
      ) : null}
    </Marker>
  )
}

export default DriveMarker
