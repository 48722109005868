import React from "react"
import PropTypes from "prop-types"
import { Card as BootstrapCard, Button } from "react-bootstrap"
import haversine from "haversine"
import { FiveStar } from "./FiveStar"
import EmptyDrive from "../../images/empty-drive.jpg"

export const Card = ({
  title,
  photoUrl,
  location,
  referenceLocation,
  buttonText,
  onButtonClick,
  onClick,
  rating,
  selected,
  onSelect,
}) => {
  const distance = React.useMemo(() => {
    if (!location || !referenceLocation) {
      return
    }

    return haversine(referenceLocation, location, {
      unit: "mile",
      format: "{lat,lng}",
    })
  }, [location, referenceLocation])

  const photoSrc = photoUrl ? photoUrl : EmptyDrive

  return (
    <BootstrapCard bg="dark-light" style={{ flex: 1 }} onClick={onClick}>
      <label
        className="container"
        style={{
          position: "absolute",
          right: 15,
          top: 15,
          height: "1.5em",
          width: "1.5em",
        }}
      >
        <input type="checkbox" onClick={onSelect} checked={selected} />
        <span className="checkmark" />
      </label>
      <BootstrapCard.Img
        variant="top"
        src={photoSrc}
        style={{ height: "10em", objectFit: "cover", width: "100%" }}
      />
      <BootstrapCard.Body>
        <BootstrapCard.Title>{title}</BootstrapCard.Title>
        <FiveStar rating={rating} />
        {distance ? <div>{distance} miles away</div> : null}
        {buttonText ? (
          <Button variant="primary" onClick={onButtonClick}>
            {buttonText}
          </Button>
        ) : null}
      </BootstrapCard.Body>
    </BootstrapCard>
  )
}

Card.propTypes = {
  title: PropTypes.string,
  photoUrl: PropTypes.string,
  rating: PropTypes.number,
  location: PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number }),
  referenceLocation: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
  onSelect: PropTypes.func,
  onClick: PropTypes.func,
}
