import { WebMercatorViewport } from "react-map-gl"

const getBoundsOfLineString = (lineString) => {
  let lowestLatitude, highestLatitude, highestLongitude, lowestLongitude

  for (const waypoint of lineString.coordinates) {
    const [lng, lat] = waypoint
    if (lowestLatitude === undefined || lat < lowestLatitude) {
      lowestLatitude = lat
    }

    if (highestLatitude === undefined || lat > highestLatitude) {
      highestLatitude = lat
    }

    if (highestLongitude === undefined || lng > highestLongitude) {
      highestLongitude = lng
    }

    if (lowestLongitude === undefined || lng < lowestLongitude) {
      lowestLongitude = lng
    }
  }

  return {
    southwest: { lat: lowestLatitude, lng: lowestLongitude },
    northeast: { lat: highestLatitude, lng: highestLongitude },
  }
}

export const getViewportForLineString = (map, lineString) => {
  const { southwest, northeast } = getBoundsOfLineString(lineString)
  const newViewport = new WebMercatorViewport({
    width: map.getContainer().clientWidth,
    height: map.getContainer().clientHeight,
  }).fitBounds(
    [
      [southwest.lng, southwest.lat],
      [northeast.lng, northeast.lat],
    ],
    {
      padding: {
        top: 100,
        bottom: 100,
        right: 100,
        left: map.getContainer().clientWidth * 0.3,
      },
    }
  )

  return newViewport
}
