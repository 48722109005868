import React from "react"
import { Button } from "react-bootstrap"
import PropTypes from "prop-types"
import Route from "../../images/route.png"

const FocusButton = ({ onClick }) => {
  const _onClick = React.useCallback(
    (event) => {
      event.preventDefault()
      event.stopPropagation()
      onClick(event)
    },
    [onClick]
  )

  return (
    <Button
      variant="light"
      style={{
        position: "absolute",
        bottom: "3em",
        right: "3em",
        zIndex: 10,
      }}
      onClick={_onClick}
    >
      <img src={Route} alt="zoom-to-route" />
    </Button>
  )
}

FocusButton.propTypes = {
  onClick: PropTypes.func,
}

export default FocusButton
