import React from "react"
import { Col, Tab, Form, Row, Button, Spinner } from "react-bootstrap"
import { useFormik } from "formik"
import ImageUpload from "../common/ImageUpload"
import { useDriveForm } from "../../context/DriveFormContext"
import * as Yup from "yup"

const formValidationSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, "Title is too short")
    .max(50, "Title is too long")
    .required("Title is required"),
  description: Yup.string()
    .min(2, "Description is too short")
    .max(500, "Description is too long"),
  file: Yup.mixed().test("file", "Improper file", (file) => true),
  isPublic: Yup.boolean().required(),
})

export function DriveForm({ onSave }) {
  const { formState } = useDriveForm()
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
    dirty,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: {
      title: formState.title || "",
      description: formState.description || "",
      file: undefined,
      isPublic: formState.isPublic || false,
    },
    validationSchema: formValidationSchema,
    onSubmit: (values) => {
      onSave(values)
    },
  })

  React.useEffect(() => {
    setValues((s) => ({
      title: formState.title || s.title,
      description: formState.description || s.description,
      file: s.file,
      isPublic: formState.isPublic || s.isPublic,
    }))
  }, [formState, setValues])

  const onFileSelected = React.useCallback(
    (f) => {
      setFieldValue("file", f)
    },
    [setFieldValue]
  )

  return (
    <Tab.Container defaultActiveKey="details">
      <Row className="d-flex flex-column mx-0 h-100">
        <Form
          data-testid="drive-form"
          onSubmit={handleSubmit}
          className="h-100 d-flex flex-column"
        >
          <Row className="mx-0" style={{ flex: 18 }}>
            <Col className="px-0">
              <Tab.Content className="h-100">
                <Tab.Pane eventKey="details" className="h-100">
                  <div
                    className="h-100 d-flex flex-column"
                    style={{ gap: "1.5em" }}
                  >
                    <ImageUpload
                      onFileSelected={onFileSelected}
                      previewUrl={formState.downloadPhotoUrl}
                    />
                    <div>
                      <Form.Label htmlFor="title">Drive Title *</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Drive title"
                        name="title"
                        id="title"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.title && !!touched.title}
                        className="bg-dark-light"
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.title}
                      </Form.Control.Feedback>
                    </div>
                    <div>
                      <Form.Label htmlFor="description">Description</Form.Label>
                      <Form.Control
                        style={{
                          boxSizing: "border-box",
                          height: "6em",
                        }}
                        className="bg-dark-light"
                        type="text"
                        id="description"
                        as="textarea"
                        maxLength={500}
                        placeholder="Describe your drive"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          !!errors.description && !!touched.description
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.description}
                      </Form.Control.Feedback>
                    </div>
                    <div>
                      <Form.Check
                        id="isPublic"
                        type="checkbox"
                        label="Drive is visible to all users"
                        custom
                        value={values.isPublic}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                </Tab.Pane>
                {/* <Tab.Pane eventKey="waypoints" className="h-100">
                  <div className="h-100 d-flex flex-column">
                    <div style={{ flex: 1 }}>
                      {waypointList.length > 0 ? (
                        <ol>{waypointList}</ol>
                      ) : (
                        <p>Add waypoints by clicking on the map</p>
                      )}
                    </div>
                  </div>
                </Tab.Pane> */}
              </Tab.Content>
            </Col>
          </Row>
          <Row className="mx-0 d-flex flex-row-reverse" style={{ flex: 1 }}>
            <Button
              type="submit"
              variant="outline-primary"
              disabled={!dirty || !isValid || formState.waypoints.length < 2}
            >
              Save
              {formState.saving ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  data-testid="loading-spinner"
                  aria-hidden="true"
                  style={{ marginLeft: 5, marginBottom: 3 }}
                />
              ) : null}
            </Button>
          </Row>
        </Form>
      </Row>
    </Tab.Container>
  )
}
