import React from "react"
import DriveMarker from "./DriveMarker"
import { Source, Layer } from "react-map-gl"
import PropTypes from "prop-types"

const DriveLine = ({
  waypoints,
  lineString,
  onWaypointClick,
  onWaypointMoved,
}) => {
  const [start, thruPoints, destination] = React.useMemo(() => {
    return [
      waypoints.length > 0 ? waypoints[0] : null,
      waypoints.length > 2 ? waypoints.slice(1, waypoints.length - 1) : [],
      waypoints.length > 1 ? waypoints[waypoints.length - 1] : null,
    ]
  }, [waypoints])

  if (start === null) {
    return null
  }

  return (
    <>
      <DriveMarker
        lat={start.lat}
        lng={start.lng}
        onClick={onWaypointClick}
        onDragEnd={onWaypointMoved}
        type="bookend"
      />
      {thruPoints.map((w, index) => (
        <DriveMarker
          key={`${w.lat}_${w.lng}`}
          lat={w.lat}
          lng={w.lng}
          onClick={onWaypointClick}
          onDragEnd={onWaypointMoved}
          type="thru"
          text={index + 1}
        />
      ))}
      {destination ? (
        <DriveMarker
          lat={destination.lat}
          lng={destination.lng}
          onClick={onWaypointClick}
          onDragEnd={onWaypointMoved}
          type="bookend"
        />
      ) : null}
      {lineString ? (
        <Source
          id="directions"
          type="geojson"
          data={{
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                properties: {},
                geometry: lineString,
              },
            ],
          }}
        >
          <Layer
            type="line"
            paint={{
              "line-color": "#4480b9",
              "line-width": 10,
            }}
            layout={{
              "line-join": "round",
              "line-cap": "round",
            }}
          />
          <Layer
            type="line"
            paint={{ "line-color": "#6ba6f4", "line-width": 6 }}
            layout={{
              "line-join": "round",
              "line-cap": "round",
            }}
          />
        </Source>
      ) : null}
    </>
  )
}

DriveLine.defaultProps = {
  waypoints: [],
  lineString: undefined,
}

DriveLine.propTypes = {
  waypoints: PropTypes.array,
  lineString: PropTypes.shape({
    type: PropTypes.string,
    coordinate: PropTypes.array,
  }),
  onWaypointClicked: PropTypes.func,
  onWaypointMoved: PropTypes.func,
}

export default DriveLine
