import React from "react"
import Helmet from "react-helmet"
import MapOverlayCard from "../components/common/MapOverlayCard"

export const DrivePacks = ({ ...rest }) => {
  return (
    <>
      <Helmet>
        <title>Drive Packs</title>
      </Helmet>
      <MapOverlayCard breadcrumbs={["Drive Packs"]}>
        Page not yet implemented
      </MapOverlayCard>
    </>
  )
}
