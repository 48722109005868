import React from "react"
import Helmet from "react-helmet"
import { Card } from "../components/common/Card"
import { CardGrid } from "../components/common/CardGrid"
import MapOverlayCard from "../components/common/MapOverlayCard"
import { useMap } from "../context/MapContext"
import { useDb } from "../hooks/useDb"
import { useUser } from "../hooks/useUser"

export const Drives = ({ navigate }) => {
  const { mapState } = useMap()
  const { fetchDrives } = useDb()
  const { user } = useUser()
  const [drives, setDrives] = React.useState([])

  React.useEffect(() => {
    fetchDrives(user)
      .then((d) => setDrives(d))
      .catch((err) => console.error(err))
  }, [user, fetchDrives])

  const driveCards = React.useMemo(() => {
    return drives.map((d) => (
      <Card
        key={d.documentID}
        title={d.title}
        photoUrl={d.downloadPhotoUrl}
        location={d.startLocation}
        referenceLocation={mapState.userLocation}
        onClick={() => navigate(`${d.documentID}`)}
      />
    ))
  }, [drives, mapState.userLocation, navigate])

  return (
    <>
      <Helmet>
        <title>Drives</title>
      </Helmet>
      <MapOverlayCard breadcrumbs={["Drives"]}>
        <CardGrid>{driveCards}</CardGrid>
      </MapOverlayCard>
    </>
  )
}
