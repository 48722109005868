import React from "react"
import { useQuery } from "react-query"
import { DriveFormProvider, useDriveForm } from "../context/DriveFormContext"
import MapOverlayCard from "../components/common/MapOverlayCard"
import { DriveForm } from "../components/forms/DriveForm"
import { useDb } from "../hooks/useDb"
import { useUser } from "../hooks/useUser"
import { useFileUpload } from "../hooks/useFileUpload"
import {
  addWaypoint,
  initForm,
  moveWaypoint,
  removeWaypoint,
  resetRouteDetails,
  setRouteDetails,
  setSaving,
} from "../reducers/DriveReducer"
import { Container, Row } from "react-bootstrap"
import { getViewportForLineString } from "../helpers/map-utils"
import { useMap } from "../context/MapContext"
import DriveLine from "../components/map/DriveLine"
import FocusButton from "../components/common/FocusButton"
import { setMapViewport } from "../reducers/MapStateReducer"
import { Helmet } from "react-helmet"
import { useToast } from "../context/ToastContext"

const InvalidPermissionsPage = () => {
  return (
    <Container className="align-items-center">
      <Row className="justify-content-center">
        <h1>Invalid Permissions</h1>
      </Row>
    </Container>
  )
}

const fetchDirections = async (coordinates) => {
  const res = await fetch(
    `https://api.mapbox.com/directions/v5/mapbox/driving/${coordinates}?access_token=${process.env.GATSBY_MAPBOX_KEY}`
  )

  if (!res.ok) {
    throw new Error(
      `Directions request failed with status code ${res.status} and text ${res.statusText}`
    )
  }

  return res.json()
}

const getDirections = async (waypoints) => {
  const directions = await fetchDirections(
    waypoints.map((w) => `${w.lng},${w.lat}`).join(";")
  )
  let route = directions.routes.length > 0 ? directions.routes[0] : null

  return {
    uuid: directions.uuid,
    waypoints: directions.waypoints,
    route,
  }
}

export const DriveEditor = ({ navigate }) => {
  const { showToast } = useToast()
  const { user } = useUser()
  const { formState, dispatch } = useDriveForm()

  const onWaypointRemoved = React.useCallback(
    (mouseEvent, marker) => {
      mouseEvent.preventDefault()
      mouseEvent.stopPropagation()
      dispatch(removeWaypoint(marker))
    },
    [dispatch]
  )

  const updateWaypointLocation = React.useCallback(
    (mouseEvent, oldLocation) => {
      mouseEvent.preventDefault()
      mouseEvent.stopPropagation()
      dispatch(
        moveWaypoint(oldLocation, {
          lat: mouseEvent.lngLat[1],
          lng: mouseEvent.lngLat[0],
        })
      )
    },
    [dispatch]
  )

  const onMouseUp = React.useCallback(
    (mouseEvent) => {
      mouseEvent.preventDefault()
      mouseEvent.stopPropagation()
      const wasDragged = mouseEvent.deltaX > 5 || mouseEvent.deltaY > 5
      if (wasDragged) {
        return
      }

      if (
        formState.waypoints.length + 1 >
        user.protectedValues.memberLevel.maxWaypoints()
      ) {
        showToast({
          title: "You have reached your waypoint limit",
          message:
            "Upgrade to the next level in order to get access to more waypoints",
          timeout: 10000,
        })
        return
      }

      dispatch(
        addWaypoint({
          lat: mouseEvent.lngLat[1],
          lng: mouseEvent.lngLat[0],
        })
      )
    },
    [
      dispatch,
      formState.waypoints.length,
      user.protectedValues.memberLevel,
      showToast,
    ]
  )

  const renderDriveLines = React.useCallback(() => {
    return formState.waypoints ? (
      <DriveLine
        waypoints={formState.waypoints}
        lineString={formState.lineString}
        onWaypointClick={onWaypointRemoved}
        onWaypointMoved={updateWaypointLocation}
      />
    ) : null
  }, [
    formState.waypoints,
    formState.lineString,
    onWaypointRemoved,
    updateWaypointLocation,
  ])

  const focusViewport = React.useCallback(
    (map, mapDispatch) => {
      const newViewport = getViewportForLineString(map, formState.lineString)
      mapDispatch(setMapViewport(newViewport))
    },
    [formState.lineString]
  )

  const renderFocusButton = React.useCallback(
    (map, mapDispatch) => {
      if (!formState.lineString) {
        return null
      }
      return <FocusButton onClick={() => focusViewport(map, mapDispatch)} />
    },
    [formState.lineString, focusViewport]
  )

  const mapActions = React.useMemo(() => {
    return {
      renderDriveLines,
      onMouseUp,
      focusViewport,
      renderFocusButton,
    }
  }, [renderDriveLines, onMouseUp, renderFocusButton, focusViewport])
  const { mapState, dispatch: dispatchMapAction } = useMap(mapActions)

  const { createDrive, updateDrive, addImageToDrive, fetchDrive } = useDb()
  const { uploadFile } = useFileUpload()
  const [hasPannedMap, setHasPannedMap] = React.useState(false)

  useQuery(
    ["directions", formState.waypoints],
    ({ queryKey }) => getDirections(queryKey[1]),
    {
      enabled: formState.waypoints?.length > 1,
      onSuccess: (data) => {
        if (data?.route) {
          dispatch(
            setRouteDetails(
              data.route.geometry,
              data.route.distance,
              data.route.duration
            )
          )
        } else {
          dispatch(resetRouteDetails())
        }
      },
    }
  )

  React.useEffect(() => {
    if (formState.lineString && !hasPannedMap) {
      const newViewport = getViewportForLineString(
        mapState.map,
        formState.lineString
      )
      dispatchMapAction(setMapViewport(newViewport))
    }

    setHasPannedMap(true)
  }, [dispatchMapAction, mapState.map, formState.lineString, hasPannedMap])

  React.useEffect(() => {
    if (formState.waypoints?.length < 2) {
      dispatch(resetRouteDetails())
    }
  }, [formState.waypoints, dispatch])

  const saveDrive = React.useCallback(
    async ({ isPublic, title, description, file }) => {
      const driveInfo = {
        ...formState,
        title,
        description,
        isPublic,
      }

      let drive
      dispatch(setSaving(true))
      if (formState.documentID) {
        drive = await updateDrive(formState.documentID, driveInfo)
      } else {
        drive = await createDrive(user, driveInfo)
      }

      if (
        file &&
        `drives/${drive.documentID}/${file.name}` !== drive.photoUrl
      ) {
        const fileUrl = await uploadFile(
          file,
          `drives/${drive.documentID}/${file.name}`
        )
        await addImageToDrive(drive.documentID, fileUrl)
      }
      const driveData = await fetchDrive(drive.documentID)
      dispatch(initForm(driveData))
    },
    [
      addImageToDrive,
      createDrive,
      dispatch,
      fetchDrive,
      formState,
      updateDrive,
      uploadFile,
      user,
    ]
  )

  if (formState.error && formState.error.message === "Invalid Permissions") {
    return <InvalidPermissionsPage />
  } else if (
    formState.error &&
    formState.error.message === "Drive does not exist"
  ) {
    navigate("/404")
    return null
  }

  return (
    <>
      <Helmet>
        <title>Map</title>
      </Helmet>
      <MapOverlayCard breadcrumbs={["Drives", "Details"]}>
        <DriveForm onSave={saveDrive} />
      </MapOverlayCard>
    </>
  )
}

export const DriveForms = ({ children, driveId, ...rest }) => {
  return (
    <DriveFormProvider driveId={driveId}>
      <DriveEditor {...rest} />
    </DriveFormProvider>
  )
}
