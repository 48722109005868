export const InitialMapState = {
  userLocation: null,
  hasPannedMap: false,
  map: null,
  userLocationInit: false,
  mapViewport: {
    zoom: 12,
    latitude: 0,
    longitude: 0,
  },
}

export const setMap = (map) => ({
  type: "@@MAP/SET_MAP",
  payload: map,
})

export const setUserLocation = (location) => {
  if (!location.latitude || !location.longitude) {
    throw new Error(
      "Malformed user location supplied. Must include longitude and latitude keys"
    )
  }
  return {
    type: "@@MAP/SET_USER_LOCATION",
    payload: location,
  }
}

export const setMapViewport = (viewport) => ({
  type: "@@MAP/SET_MAP_VIEWPORT",
  payload: viewport,
})

export const initUserLocation = (location) => {
  if (!location.latitude || !location.longitude) {
    throw new Error(
      "Malformed user location supplied. Must include longitude and latitude keys"
    )
  }
  return {
    type: "@@MAP/INIT_USER_LOCATION",
    payload: location,
  }
}

export const MapStateReducer = (state, action) => {
  switch (action.type) {
    case "@@MAP/SET_MAP":
      return {
        ...state,
        map: action.payload,
      }
    case "@@MAP/INIT_USER_LOCATION":
      return {
        ...state,
        userLocation: {
          lat: action.payload.latitude,
          lng: action.payload.longitude,
        },
        userLocationInit: true,
      }
    case "@@MAP/SET_USER_LOCATION":
      return {
        ...state,
        userLocation: {
          lat: action.payload.latitude,
          lng: action.payload.longitude,
        },
      }
    case "@@MAP/SET_MAP_VIEWPORT":
      return {
        ...state,
        mapViewport: action.payload,
      }
    default:
      return state
  }
}
