import React from "react"
import Toast from "react-bootstrap/Toast"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-solid-svg-icons"
import { useToast } from "../../context/ToastContext"

const HooksToastSection = () => {
  const { state, dismissToast } = useToast()

  const statusClass = React.useMemo(() => {
    if (!state.type) {
      return ""
    }

    switch (state.type) {
      case "status":
        return ""
      case "error":
        return "error"
      case "success":
        return "success"
      case "warning":
        return "warning"
      default:
        console.error(`Unknown toast type ${state.type}`)
        return ""
    }
  }, [state.type])

  return (
    <Toast className={`rallista-alert center ${statusClass}`} show={state.show}>
      <Toast.Header closeButton={false}>
        <strong className="py-2 mr-auto">{state.title}</strong>
        <button onClick={dismissToast}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </Toast.Header>
      <Toast.Body>{state.message}</Toast.Body>
    </Toast>
  )
}

export default HooksToastSection
