import mapboxgl from "mapbox-gl"
import React from "react"
import ReactMapGL from "react-map-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import { useMap } from "../../context/MapContext"
import { setMap } from "../../reducers/MapStateReducer"

// https://github.com/mapbox/mapbox-gl-js/issues/10565#issuecomment-899083108
/* eslint-disable import/no-webpack-loader-syntax, import/no-unresolved,  */
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default

const styles = {
  container: {
    height: "100%",
    width: "100%",
    padding: 0,
    margin: 0,
  },
}

const Map = ({ showMap }) => {
  const { mapState, dispatch: mapDispatch, mapActions } = useMap()
  const mapRef = React.useRef(mapState.map)

  const onLoad = React.useCallback(
    (loadEvent) => {
      if (loadEvent.target) {
        mapDispatch(setMap(loadEvent.target))
        mapRef.current = loadEvent.target
      }
    },
    [mapDispatch]
  )

  return (
    <div style={styles.container}>
      <ReactMapGL
        mapboxApiAccessToken={process.env.GATSBY_MAPBOX_KEY}
        mapStyle="mapbox://styles/rallista/ckkbdy38r1fw618qrh3wz2gfq"
        {...mapState.mapViewport}
        height={"100%"}
        width={"100%"}
        dragRotate={false}
        onViewportChange={mapActions.onViewportChange}
        onClick={mapActions.onMouseUp}
        onLoad={onLoad}
        visible={showMap}
      >
        {mapActions.renderDriveLines()}
      </ReactMapGL>
      {mapActions.renderFocusButton(mapRef.current, mapDispatch)}
    </div>
  )
}

export default Map
