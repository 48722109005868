import React from "react"
import { Helmet } from "react-helmet"
import MapOverlayCard from "../components/common/MapOverlayCard"
import { Card } from "../components/common/Card"

export const Events = ({ ...rest }) => {
  const [checked, setChecked] = React.useState(true)

  return (
    <>
      <Helmet>
        <title>Events</title>
      </Helmet>
      <MapOverlayCard breadcrumbs={["Events"]}>
        <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
          <Card
            title="Drive #1"
            photoUrl="https://thegorgeguide.com/wp-content/uploads/2020/06/Rowena-Crest-copy-1.jpg"
            rating={3.4}
            location={{ lat: 123, lng: 45 }}
            referenceLocation={{ lat: 123, lng: 45 }}
            buttonText="Edit"
            onButtonClick={() => console.log("button clicked")}
          />
          <Card
            title="Drive #1"
            rating={3.4}
            location={{ lat: 123, lng: 45 }}
            referenceLocation={{ lat: 123, lng: 45 }}
            buttonText="Edit"
            onButtonClick={() => console.log("button clicked")}
            selected={checked}
            onSelect={() => setChecked(!checked)}
          />
        </div>
      </MapOverlayCard>
    </>
  )
}
