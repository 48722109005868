import * as polylineDecoder from "@mapbox/polyline"

export const initialDriveFormState = {
  waypoints: [],
  title: undefined,
  description: undefined,
  photoUrl: undefined,
  polyline: undefined,
  documentID: undefined,
  userDocumentID: undefined,
  distance: undefined,
  expectedTravelTime: undefined,
  isPublic: undefined,
  error: undefined,
  version: undefined,
  saving: false,
}

export const setSaving = (value) => ({ type: "SET_SAVING", payload: value })

export const resetForm = () => ({ type: "RESET_FORM" })

export const setError = (
  /** @type {any} */ message,
  /** @type {{ [key: string]: any; }} */ data
) => ({
  type: "SET_ERROR",
  payload: { message, data },
})

export const initForm = (drive) => {
  const formState = {
    waypoints: drive.route?.waypoints.map((w) => ({
      lat: w.location[1],
      lng: w.location[0],
    })),
    title: drive.title,
    description: drive.description,
    photoUrl: drive.photoUrl,
    downloadPhotoUrl: drive.downloadPhotoUrl,
    polyline: drive.route?.route.polyline,
    documentID: drive.documentID,
    userDocumentID: drive.userDocumentID,
    distance: drive.route?.route.distance,
    expectedTravelTime: drive.route?.route.expectedTravelTime,
    isPublic: drive.isPublic,
    error: undefined,
    saving: false,
    version: drive.version,
  }
  return {
    type: "INIT_FORM",
    payload: {
      ...formState,
    },
  }
}

export const setRouteDetails = (polyline, distance, travelTime) => ({
  type: "SET_ROUTE_DETAILS",
  payload: { polyline, distance, expectedTravelTime: travelTime },
})

export const resetRouteDetails = () => ({ type: "RESET_ROUTE_DETAILS" })

export const addWaypoint = (waypoint) => ({
  type: "ADD_WAYPOINT",
  payload: waypoint,
})

export const removeWaypoint = (marker) => ({
  type: "REMOVE_WAYPOINT",
  payload: marker,
})

export const moveWaypoint = (waypoint, newLocation) => ({
  type: "MOVE_WAYPOINT",
  payload: { waypoint, newLocation },
})

const getLineString = (polyline) =>
  polyline ? polylineDecoder.toGeoJSON(polyline) : undefined

export default function driveReducer(state, action) {
  switch (action.type) {
    case "SET_SAVING": {
      return {
        ...state,
        saving: action.payload,
      }
    }
    case "RESET_FORM":
      return initialDriveFormState
    case "SET_ERROR":
      return {
        ...state,
        error: {
          message: action.payload.message,
          data: action.payload.data,
        },
      }
    case "INIT_FORM":
      return {
        ...action.payload,
        lineString: getLineString(action.payload.polyline),
      }
    case "SET_ROUTE_DETAILS":
      return {
        ...state,
        polyline: action.payload.polyline,
        lineString: getLineString(action.payload.polyline),
        distance: action.payload.distance,
        expectedTravelTime: action.payload.expectedTravelTime,
      }
    case "RESET_ROUTE_DETAILS":
      return {
        ...state,
        polyline: undefined,
        distance: undefined,
        expectedTravelTime: undefined,
        lineString: undefined,
      }
    case "ADD_WAYPOINT":
      return { ...state, waypoints: [...state.waypoints, action.payload] }
    case "REMOVE_WAYPOINT":
      return {
        ...state,
        waypoints: [
          ...state.waypoints.filter(
            (w) =>
              w.lat !== action.payload.props.latitude &&
              w.lng !== action.payload.props.longitude
          ),
        ],
      }
    case "MOVE_WAYPOINT":
      return {
        ...state,
        waypoints: [
          ...state.waypoints.map((w) => {
            if (
              w.lat === action.payload.waypoint.lat &&
              w.lng === action.payload.waypoint.lng
            ) {
              return action.payload.newLocation
            }
            return w
          }),
        ],
      }
    default:
      return { ...state }
  }
}
