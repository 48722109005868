import React from "react"
import { Breadcrumb } from "react-bootstrap"

export default function MapOverlayCard({ children, breadcrumbs = undefined }) {
  const breadcrumbRef = React.useRef()
  const wrapperRef = React.useRef()
  const containerRef = React.useRef()

  const crumbDisplay = React.useMemo(() => {
    if (!breadcrumbs) {
      return null
    }
    const crumbs = breadcrumbs.map((b, index) => (
      <Breadcrumb.Item
        key={b}
        linkAs="span"
        active={index === breadcrumbs.length - 1}
      >
        {b}
      </Breadcrumb.Item>
    ))

    return <Breadcrumb className="flex-1">{crumbs}</Breadcrumb>
  }, [breadcrumbs])

  const scrollContent = React.useCallback(
    (event) => {
      if (
        !containerRef.current ||
        !breadcrumbRef.current ||
        !wrapperRef.current
      ) {
        return
      }
      const currentScroll =
        event.target.scrollTop /
        (containerRef.current.scrollHeight - wrapperRef.current.offsetHeight)

      breadcrumbRef.current.style.boxShadow = `0 8px 8px 4px rgba(255, 255, 255, ${Math.min(
        Math.max(currentScroll, 0),
        0.1
      )})`
    },
    [containerRef, breadcrumbRef, wrapperRef]
  )

  return (
    <div
      id="map-overlay"
      className="wrapper bg-dark"
      ref={wrapperRef}
      style={{ marginTop: "6em" }}
    >
      <div className="h-100 d-flex flex-column">
        <div
          ref={breadcrumbRef}
          data-testid="breadcrumbs"
          className="flex-1 d-flex align-items-center top-shadow px-4 py-2"
        >
          {crumbDisplay}
        </div>
        <div
          ref={containerRef}
          data-testid="container"
          className="flex-11 pb-4 px-4 content"
          onScroll={scrollContent}
        >
          {children}
        </div>
      </div>
    </div>
  )
}
