import React from "react"

const ToastContext = React.createContext(undefined)

export const ToastProvider = ({ children }) => {
  const [toastState, setToastState] = React.useState({
    message: undefined,
    show: false,
    title: undefined,
    type: undefined,
  })

  return (
    <ToastContext.Provider value={{ toastState, setToastState }}>
      {children}
    </ToastContext.Provider>
  )
}

const useToastContext = () => {
  const context = React.useContext(ToastContext)
  if (!context) {
    throw new Error("Cannont use useToast outside of ToastProvider")
  }
  return context
}

export const useToast = () => {
  const { setToastState, toastState } = useToastContext()

  const dismissToast = React.useCallback(() => {
    setToastState({ show: false })
  }, [setToastState])

  const showToast = React.useCallback(
    ({ title, message, timeout, type }) => {
      const _timeout = timeout ? timeout : 3000
      const _type = type ? type : "status"
      setToastState({
        show: true,
        message,
        title,
        type: _type,
      })

      setTimeout(() => {
        dismissToast()
      }, _timeout)
    },
    [setToastState, dismissToast]
  )

  return {
    showToast,
    dismissToast,
    state: toastState,
  }
}
