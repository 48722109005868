import React from "react"
import { Router } from "@gatsbyjs/reach-router"
import { AppNav } from "../../components/common"
import { DriveForms } from "../../client-side-pages/DriveEditor"
import ProtectedRoute from "../../components/common/ProtectedRoute"
import Div100Vh from "react-div-100vh"
import { QueryClient, QueryClientProvider } from "react-query"
import { Drives } from "../../client-side-pages/Drives"
import { DrivePacks } from "../../client-side-pages/DrivePacks"
import { Events } from "../../client-side-pages/Events"
import { MapProvider, useMap } from "../../context/MapContext"
import Map from "../../components/map/Map"
import { ToastProvider } from "../../context/ToastContext"
import HooksToastSection from "../../components/common/HooksToastSection"

const DashboardDisplay = () => {
  const { resetMapActions } = useMap()

  const resetMapDisplay = React.useCallback(() => {
    resetMapActions()
  }, [resetMapActions])

  return (
    <Div100Vh>
      <AppNav />
      <div className="h-100 w-100" style={{ paddingTop: "6em" }}>
        <Map showMap={true} />
        <Router basepath="/dashboard">
          <ProtectedRoute
            path="/"
            component={DriveForms}
            onEnter={resetMapDisplay}
          />
          <ProtectedRoute
            path="map"
            component={DriveForms}
            onEnter={resetMapDisplay}
          />
          <ProtectedRoute
            path="map/:driveId"
            component={DriveForms}
            onEnter={resetMapDisplay}
          />
          <ProtectedRoute
            path="drive-packs"
            component={DrivePacks}
            onEnter={resetMapDisplay}
          />
          <ProtectedRoute
            path="drives"
            component={Drives}
            onEnter={resetMapDisplay}
          />
          <ProtectedRoute
            path="drives/:driveId"
            component={DriveForms}
            onEnter={resetMapDisplay}
          />
          <ProtectedRoute
            path="events"
            component={Events}
            onEnter={resetMapDisplay}
          />
        </Router>
      </div>
      <HooksToastSection />
    </Div100Vh>
  )
}

const queryClient = new QueryClient()

const Dashboard = ({ ...rest }) => {
  return (
    <ToastProvider>
      <MapProvider>
        <QueryClientProvider client={queryClient}>
          <DashboardDisplay {...rest} />
        </QueryClientProvider>
      </MapProvider>
    </ToastProvider>
  )
}

export default Dashboard
