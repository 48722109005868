import React from "react"
import {
  InitialMapState,
  initUserLocation,
  MapStateReducer,
  setMapViewport,
} from "../reducers/MapStateReducer"

const MapContext = React.createContext(undefined)

export const MapProvider = ({ children }) => {
  const [mapState, dispatch] = React.useReducer(
    MapStateReducer,
    InitialMapState
  )

  const [mapActions, setMapActions] = React.useState({
    onViewportChange: React.useCallback(
      (newViewPort) => {
        dispatch(setMapViewport(newViewPort))
      },
      [dispatch]
    ),
    renderDriveLines: React.useCallback(() => null, []),
    onMouseUp: React.useCallback(() => {}, []),
    focusViewport: React.useCallback((map) => {}, []),
    renderFocusButton: React.useCallback(() => null, []),
  })

  React.useEffect(() => {
    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        dispatch(initUserLocation(position.coords))
        if (!mapState.userLocationInit) {
          dispatch(
            setMapViewport({
              zoom: 12,
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            })
          )
        }
      },
      (e) => {
        console.error("Failed to retrieve location", e)
        dispatch(
          initUserLocation({
            latitude: 37.0902,
            longitude: -95.7129,
          })
        )
        if (!mapState.userLocationInit) {
          dispatch(
            setMapViewport({
              zoom: 12,
              latitude: 37.0902,
              longitude: -95.7129,
            })
          )
        }
      }
    )

    return () => navigator.geolocation.clearWatch(watchId)
  }, [dispatch, mapState.userLocationInit])

  return (
    <MapContext.Provider
      value={{ mapState, dispatch, mapActions, setMapActions }}
    >
      {children}
    </MapContext.Provider>
  )
}

export const useMap = (actions) => {
  const mapContext = React.useContext(MapContext)

  if (!mapContext) {
    throw new Error("Map context must be used within the MapProvider")
  }

  const { mapState, mapActions, setMapActions, dispatch } = mapContext

  const defaultOnViewportChange = React.useCallback(
    (newViewPort) => {
      dispatch(setMapViewport(newViewPort))
    },
    [dispatch]
  )
  const defaultRender = React.useCallback(() => null, [])
  const defaultOnMouseUp = React.useCallback(() => {}, [])
  const defaultFocusViewport = React.useCallback((map, dispatch) => {}, [])

  const defaultMapActions = React.useMemo(
    () => ({
      onViewportChange: defaultOnViewportChange,
      renderDriveLines: defaultRender,
      onMouseUp: defaultOnMouseUp,
      focusViewport: defaultFocusViewport,
      renderFocusButton: defaultRender,
    }),
    [
      defaultOnViewportChange,
      defaultRender,
      defaultOnMouseUp,
      defaultFocusViewport,
    ]
  )

  const focusViewport = React.useCallback(() => {
    if (actions.focusViewport) {
      return actions.focusViewport(mapState.map, dispatch)
    } else {
      return defaultFocusViewport(mapState.map, dispatch)
    }
  }, [mapState.map, mapActions.focusViewport, dispatch])

  React.useEffect(() => {
    setMapActions((s) => ({
      ...s,
      ...actions,
      focusViewport,
    }))
  }, [actions])

  const resetMapActions = React.useCallback(() => {
    setMapActions(defaultMapActions)
  }, [setMapActions, defaultMapActions])

  return {
    mapState: mapContext.mapState,
    dispatch: mapContext.dispatch,
    mapActions: mapContext.mapActions,
    resetMapActions,
  }
}
