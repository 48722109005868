import React from "react"

export const CardGrid = ({ children }) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(160px, 1fr))",
        columnGap: "1em",
        rowGap: "1em",
      }}
    >
      {children}
    </div>
  )
}
