import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons"
import { faStar as faStarOutline } from "@fortawesome/free-regular-svg-icons"

const StarOutline = () => (
  <FontAwesomeIcon data-testid="star-outline" icon={faStarOutline} />
)
const HalfStar = () => (
  <FontAwesomeIcon data-testid="star-half" icon={faStarHalfAlt} />
)
const FullStar = () => <FontAwesomeIcon data-testid="star-full" icon={faStar} />

export const FiveStar = ({ rating: totalRating }) => {
  const stars = React.useMemo(() => {
    let rating = totalRating || 0
    let stars = []

    while (stars.length < 5) {
      if (rating > 1) {
        stars.push(<FullStar key={rating} />)
      } else if (rating > 0) {
        let empty = Math.abs(0 - rating)
        let quart = Math.abs(0.25 - rating)
        let half = Math.abs(0.5 - rating)
        let three = Math.abs(0.75 - rating)
        let full = Math.abs(1 - rating)
        let closest = Math.min(empty, quart, half, three, full)
        switch (closest) {
          case empty:
            stars.push(<StarOutline key={rating} />)
            break
          case quart:
            stars.push(<HalfStar key={rating} />)
            break
          case half:
            stars.push(<HalfStar key={rating} />)
            break
          case three:
            stars.push(<HalfStar key={rating} />)
            break
          case full:
            stars.push(<FullStar key={rating} />)
            break
          default:
            stars.push(<StarOutline key={rating} />)
            break
        }
      } else {
        stars.push(<StarOutline key={rating} />)
      }
      rating = rating - 1
    }
    return stars
  }, [totalRating])

  return <div>{stars}</div>
}
