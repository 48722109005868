import React from "react"
import { useDb } from "../hooks/useDb"
import { useUser } from "../hooks/useUser"
import driveReducer, {
  initForm,
  initialDriveFormState,
  setError,
} from "../reducers/DriveReducer"

const DriveFormContext = React.createContext(undefined)

class DriveError extends Error {}

export function DriveFormProvider({
  driveId = undefined,
  children,
  initialState = initialDriveFormState,
}) {
  const { fetchDrive } = useDb()
  const { user } = useUser()
  const [formState, dispatch] = React.useReducer(driveReducer, initialState)
  const [showChildren, setShowChildren] = React.useState(false)

  React.useEffect(() => {
    if (!driveId) {
      setShowChildren(true)
      return
    }

    const getDrive = async () => {
      const driveDoc = await fetchDrive(driveId)

      if (!driveDoc) {
        throw new DriveError("Drive does not exist")
      }

      dispatch(initForm(driveDoc))
    }

    getDrive()
      .catch((err) => {
        console.error(err)
        if (err.message === "Missing or insufficient permissions.") {
          dispatch(setError("Invalid permissions", { driveId }))
        } else if (err instanceof DriveError) {
          dispatch(setError(err.message, { driveId }))
        } else if (err.code && err.code === "permission-denied") {
          dispatch(setError("Invalid permissions", { driveId }))
        } else {
          dispatch(
            setError("Unknown error", { driveId, message: err.message, err })
          )
        }
      })
      .finally(() => setShowChildren(true))
  }, [driveId, user, fetchDrive])

  return (
    <DriveFormContext.Provider value={{ formState, dispatch }}>
      {showChildren ? children : null}
    </DriveFormContext.Provider>
  )
}

export const useDriveForm = (context = DriveFormContext) => {
  const c = React.useContext(context)

  if (!c) {
    throw new Error("useDriveForm hook must be used within DriveFormProvider")
  }

  return c
}
